
import {defineComponent, ref, watch} from "vue";
import TaskService from "@/core/services/TaskService";

export default defineComponent({
  name: "TaskSummary",
  props: {
    filter: {},
  },
  setup(props) {
    const data = ref<any>({})
    const loadData = (filter) => {
      TaskService.summary(filter.objectId, filter.objectType).then(res => {
        data.value = res;
      })
    }
    loadData(props.filter)
    watch(()=>props.filter, cb=>{
      loadData(cb)
    });
    return {
      data
    }
  }
})
